/* eslint-disable no-param-reassign */
import type {} from '@redux-devtools/extension'; // required for devtools typing
import { type ChainflipAsset } from '@chainflip/utils/chainflip';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { type ChainflipAccountId, type InjectedAccountWithMeta } from '@/shared/hooks/usePolkadot';
import type { DepositChannel } from '@/shared/hooks/useStateChainAccount';

export type StoredDepositChannel = Omit<DepositChannel, 'asset'> & {
  asset: ChainflipAsset;
};

interface Store {
  addChannel: (channel: DepositChannel) => void;
  removeChannel: (depositAddress: string) => void;
  channels: StoredDepositChannel[];
  polkadotAccount: InjectedAccountWithMeta | null;
  savePolkadotAccount: (account: InjectedAccountWithMeta | null) => void;
}

const useStore = create<Store>()(
  devtools(
    persist(
      immer((set) => ({
        polkadotAccount: null,
        savePolkadotAccount: (account) =>
          set(
            (state) => {
              state.polkadotAccount = account;
            },
            false,
            'savePolkadotAccount',
          ),
        channels: [],
        addChannel: ({ asset, ...channel }) =>
          set(
            (state) => {
              state.channels.push({
                ...channel,
                asset: asset.chainflipId,
              });
            },
            false,
            'addChannel',
          ),
        removeChannel: (depositAddress) =>
          set(
            (state) => {
              state.channels = state.channels.filter(
                (channel) => channel.depositAddress !== depositAddress,
              );
            },
            false,
            'removeChannel',
          ),
      })),
      {
        name: 'cf.liquidity-provision',
        version: 3,
        migrate: (persistedState, version) => {
          if (version === 1 && typeof persistedState === 'object') {
            // remove channels stored with previous shape as they are temporary anyway
            persistedState = { ...(persistedState as Store), channels: [] };
          }

          if (version === 2 && typeof persistedState === 'object') {
            const typedState = persistedState as Store & {
              polkadotAccount?: { id: ChainflipAccountId };
            };

            // migrate renamed id property on account object
            if (typedState.polkadotAccount) {
              typedState.polkadotAccount = {
                ...typedState.polkadotAccount,
                idSs58: typedState.polkadotAccount.id,
              };
            }
          }

          return persistedState as Store;
        },
      },
    ),
  ),
);

export default useStore;
