import { type ChainflipNetwork } from '@/shared/utils';

export const LP_APP_DOMAINS: Record<ChainflipNetwork, string> = {
  mainnet: 'lp.chainflip.io',
  perseverance: 'lp.perseverance.chainflip.io',
  sisyphos: 'lp.sisyphos.staging',
  backspin: 'lp.backspin.staging',
  unknown: 'unknown',
};

export const LP_APP_PLAUSIBLE_DOMAIN = 'liquidity-provision.chainflip.io';
